// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {media} from "utils/Media"
import {Controller, Scene} from 'react-scrollmagic';
import {graphql, StaticQuery} from "gatsby";
import CopyBox from "components/shared/CopyBox"
import HoldScene from "components/shared/HoldScene"
import ButtonHold from "components/shared/ButtonHold"
import BgImage from 'components/shared/BgImage'

const Wrapper = styled.div`
    overflow: hidden;
    
 .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 { 
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
  .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
`;

class Puppetry3 extends Component {
    render() {
        return (
            <Wrapper active={this.props.active} id="puppetry3Wrapper">
                <div id="puppetry3Scene"/>
                <Controller>
                    <Scene
                        triggerElement="#puppetry3Scene"
                        triggerHook="onLeave"
                        duration={500}
                        pin
                    >
                        {(progress) => (
                            <div className={`sticky2`}>
                                <BgImage
                                    data={this.props.data}
                                    play={progress > 0}
                                    progress={progress}
                                    noTitleBar
                                />

                                <CopyBox
                                    copyHorizontalPosition="toLeft"
                                    copyVerticalPosition="toTop"
                                    copyVerticalPositionMd="toTop"
                                    copyMdWidth="fullWidth"
                                    step1
                                >
                                    <h3>Animals</h3>
                                    <p>
                                        Many of the puppets are closely entwined with the costumes to help create a
                                        range of animal species. Examples are the cheetah, the giraffes and the gazelles
                                        among others. The gazelle is an example of Taymor’s use of ‘corporate puppetry’
                                        where one performer depicts many animals at once.
                                    </p>
                                    <p>
                                        Why do you think corporate puppetry is used in The Lion King?
                                    </p>
                                </CopyBox>

                                <ButtonHold
                                    play={progress > 0}
                                    target={this.puppetry3HoldScene}
                                />

                                <HoldScene
                                    ref={puppetry3HoldScene => this.puppetry3HoldScene = puppetry3HoldScene}
                                    bgImageSm={this.props.data.bgStep2Sm.childImageSharp.gatsbyImageData}
                    bgImageMd={this.props.data.bgStep2Md.childImageSharp.gatsbyImageData}
                    bgImageLg={this.props.data.bgStep2Lg.childImageSharp.gatsbyImageData}
                    bgImageXl={this.props.data.bgStep2.childImageSharp.gatsbyImageData}
                                    copyHorizontalPosition="toLeft"
                                    copyVerticalPosition="toMiddle"
                                    copyVerticalPositionMd="toTop"
                                    copyMdWidth="fullWidth"
                                    text="<p>Corporate puppetry allows one or two performers to bring a whole herd to life. This helps to bring an expansive environment to  life on stage.</p>"
                                />

                            </div>
                        )}
                    </Scene>
                </Controller>
            </Wrapper>
        )
    }
}


export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                 bgStep1: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt11-1-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep1Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt11-1-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep1Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt11-1-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep1Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt11-1-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
               bgStep2: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt11-2-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep2Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt11-2-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep2Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt11-2-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep2Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt11-2-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Puppetry3 data={data} active={props.active} title={props.title}/>
        )}
    />
)
