// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {media} from "utils/Media"
import {Controller, Scene} from 'react-scrollmagic';
import {graphql, StaticQuery} from "gatsby";
import CopyBox from "components/shared/CopyBox"
import HoldScene from "components/shared/HoldScene"
import ButtonHold from "components/shared/ButtonHold"
import BgImage from 'components/shared/BgImage'

const Wrapper = styled.div`
    overflow: hidden;
    
 .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 { 
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
  .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
`;

class Costumes4 extends Component {
    render() {

        let mql = window.matchMedia(media.sm).matches;

        return (
            <Wrapper active={this.props.active} id="costumes4Wrapper">
                <div id="costumes4Scene"/>
                <Controller>
                    <Scene
                        triggerElement="#costumes4Scene"
                        triggerHook="onLeave"
                        duration={500}
                        pin
                    >
                        {(progress) => (
                            <div className={`sticky2`}>
                                <BgImage
                                    data={this.props.data}
                                    play={progress > 0}
                                    progress={progress}
                                    noTitleBar
                                />

                                <CopyBox
                                    copyHorizontalPosition="toLeft"
                                    copyVerticalPosition="toTop"
                                    copyVerticalPositionMd="toTop"
                                    copyMdWidth="fullWidth"
                                    step1
                                >
                                    <h3>Rafiki</h3>
                                    <p>
                                        Rafiki is unusual because she does not wear a mask and does not operate any puppet.  Her animal persona of a mandrill monkey is created through costume and makeup. Her elaborate costume elongates her arms and shortens her legs while her makeup is used to accentuate her facial features.  Julie Taymor made Rafiki female and based part of her character on South African Sangoma, hence the medicinal items present on her costume.
                                    </p>
                                </CopyBox>

                                <ButtonHold
                                    play={progress > 0}
                                    target={this.costumes4HoldScene}
                                />

                                <HoldScene
                                    ref={costumes4HoldScene => this.costumes4HoldScene = costumes4HoldScene}
                                    bgImageSm={this.props.data.bgStep2Sm.childImageSharp.gatsbyImageData}
                    bgImageMd={this.props.data.bgStep2Md.childImageSharp.gatsbyImageData}
                    bgImageLg={this.props.data.bgStep2Lg.childImageSharp.gatsbyImageData}
                    bgImageXl={this.props.data.bgStep2.childImageSharp.gatsbyImageData}
                                    copyHorizontalPosition="toLeft"
                                    copyVerticalPosition="toMiddle"
                                    copyVerticalPositionMd="toTop"
                                    copyMdWidth="fullWidth"
                                    text="<p>Sangoma are people who are respected and trusted in their community to act as spiritual guides and traditional healers for others. They are usually women.</p>"
                                />

                            </div>
                        )}
                    </Scene>
                </Controller>
            </Wrapper>
        )
    }
}


export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                 bgStep1: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt8-1-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep1Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt8-1-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep1Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt8-1-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep1Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt8-1-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
               bgStep2: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt8-2-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep2Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt8-2-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep2Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt8-2-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep2Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt8-2-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Costumes4 data={data} active={props.active} title={props.title}/>
        )}
    />
)
