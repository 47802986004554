// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {Controller, Scene} from 'react-scrollmagic';
import {Tween, Timeline} from 'react-gsap';
import {graphql, StaticQuery} from "gatsby";
import {media} from "utils/Media"
import {GatsbyImage} from "gatsby-plugin-image";
import CopyBox from "components/shared/CopyBox"
import ButtonScroll from "components/shared/ScrollButton"
import LazyLoad from "react-lazyload"
import BgImage from 'components/shared/BgImage'
import Absolute from "components/shared/Absolute"

const Wrapper = styled.div`
    overflow: hidden;
    
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media screen and (max-width: ${props => props.theme.sizes.sm}) {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    @media ${media.sm} {
        display: block !important;
    }
  }

    .black-bg {
        background: #000;
    }

  .no-padding-top {
      top: 0;
  }
  
  .sticky, .sticky2 { 
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
  .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
    
    .animation__background {
        width: 100vw;
        height: 100vh;
        position: absolute;
        left: 0;
        z-index: 2;
        top: 0;
        
        &--step2 {
            opacity: 0;
        }
        
        &--overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            background-image: linear-gradient(#000, transparent);
        }
        
        .image {
            width: 100vw;
            height: 100vh;
        }
    }
`;

class Set4 extends Component {

    displayMobileHeading = (progress) => {
        return (
            <div className={`sticky2 black-bg`}>
                <CopyBox
                    copyHorizontalPosition="toLeft"
                    copyVerticalPosition="toTop"
                    copyVerticalPositionMd="toTop"
                    copyMdWidth="fullWidth"
                    step1
                    ontop
                >
                    <h3>Levels</h3>
                    <p>
                        The use of levels is very important both to create visual interest and to create the
                        different landscapes. Machinery and automation are key to help create this in the show.
                        Pride Rock spirals up from the stage in a circular motion using automation. The Elephant
                        Graveyard also creates multiple levels on stage, where Scar runs his domain.
                        </p>
                </CopyBox>
            </div>
        )
    }

    displayMobileScene = (progress) => {
        return (
            <div className={`sticky2`}>
                <Absolute className="no-padding-top">
                    <BgImage
                        data={this.props.data}
                        play={progress > 0}
                        progress={progress}
                        noTitleBar
                        overrideDefaultImage={true}
                    />

                </Absolute>
            </div>
        )
    }

    displayMobileScene2 = (progress) => {
        return (
            <div className={`sticky2`}>
                <Absolute className="no-padding-top">
                    <BgImage
                        data={this.props.data}
                        play={progress > 0}
                        progress={progress}
                        noTitleBar
                    />

                </Absolute>
            </div>
        )
    }

    displayScene = (progress) => {
        return (
            <div className={`sticky2`}>
                <Timeline totalProgress={progress} paused>
                    <Tween
                        to={{
                            scale: 1.1
                        }}
                        ease="Strong.easeOut"
                        totalProgress={progress}
                        paused
                    >
                        <div className="animation__background">
                            <Tween
                                to={{
                                    backgroundImage: "linear-gradient(transparent, transparent)"
                                }}
                                ease="Strong.easeOut"
                                totalProgress={progress}
                                paused
                            >
                                <div className="animation__background--overlay"/>
                            </Tween>
                            <LazyLoad height={"100%"} once>
                            <GatsbyImage image={this.props.data.bgStep1Sm.childImageSharp.gatsbyImageData}
                                alt=""
                                title=""
                                className="image d-block d-sm-none"
                            />
                            </LazyLoad>
                            <LazyLoad height={"100%"} once>
                            <GatsbyImage image={this.props.data.bgStep1Md.childImageSharp.gatsbyImageData}
                                alt=""
                                title=""
                                className="image d-none d-sm-block d-lg-none"
                            />
                            </LazyLoad>
                            <LazyLoad height={"100%"} once>
                            <GatsbyImage image={this.props.data.bgStep1Lg.childImageSharp.gatsbyImageData}
                                alt=""
                                title=""
                                className="image d-none d-lg-block d-xl-none"
                            />
                            </LazyLoad>
                            <LazyLoad height={"100%"} once>
                            <GatsbyImage image={this.props.data.bgStep1.childImageSharp.gatsbyImageData}
                                alt=""
                                title=""
                                className="image d-none d-xl-block"
                            />
                            </LazyLoad>
                        </div>
                    </Tween>

                    <CopyBox
                        copyHorizontalPosition="toLeft"
                        copyVerticalPosition="toTop"
                        copyVerticalPositionMd="toTop"
                        copyMdWidth="fullWidth"
                        step1
                        ontop
                    >
                        <h3>Levels</h3>
                        <p>
                            The use of levels is very important both to create visual interest and to create the
                            different landscapes. Machinery and automation are key to help create this in the show.
                            Pride Rock spirals up from the stage in a circular motion using automation. The Elephant
                            Graveyard also creates multiple levels on stage, where Scar runs his domain.
                        </p>
                    </CopyBox>

                    <Tween
                        to={{opacity: 1, scale: 1.1}}
                        duration={3}
                    >
                        <div className="animation__background animation__background--step2">
                            <LazyLoad height={"100%"} once>
                            <GatsbyImage image={this.props.data.bgStep2Sm.childImageSharp.gatsbyImageData}
                                alt=""
                                title=""
                                className="image d-block d-sm-none"
                            />
                            </LazyLoad>
                            <LazyLoad height={"100%"} once>
                            <GatsbyImage image={this.props.data.bgStep2Md.childImageSharp.gatsbyImageData}
                                alt=""
                                title=""
                                className="image d-none d-sm-block d-lg-none"
                            />
                            </LazyLoad>
                            <LazyLoad height={"100%"} once>
                            <GatsbyImage image={this.props.data.bgStep2Lg.childImageSharp.gatsbyImageData}
                                alt=""
                                title=""
                                className="image d-none d-lg-block d-xl-none"
                            />
                            </LazyLoad>
                            <LazyLoad height={"100%"} once>
                            <GatsbyImage image={this.props.data.bgStep2.childImageSharp.gatsbyImageData}
                                alt=""
                                title=""
                                className="image d-none d-xl-block"
                            />
                            </LazyLoad>
                        </div>
                    </Tween>
                </Timeline>
                <ButtonScroll story progress={progress}/>
            </div>
        )
    }

    render() {
        let mql = window.matchMedia(media.sm).matches;
        if (mql) {
            return (
                <Wrapper active={this.props.active} id="set4Wrapper">
                    <div id="set4Scene" ref={set4Scene => this.set4Scene = set4Scene}/>
                    <Controller>
                        <Scene
                            triggerElement="#set4Scene"
                            triggerHook="onLeave"
                            duration={2000}
                            pin
                        >
                            {(progress) => this.displayScene(progress)}
                        </Scene>
                    </Controller>
                </Wrapper>
            )
        } else {
            return (
                <>
                    <Wrapper active={this.props.active} id="set4Wrapper">
                        <div id="set4Scene"/>
                        <Controller>
                            <Scene
                                triggerElement="#set4Scene"
                                triggerHook="onLeave"
                                duration={2000}
                                pin
                                enabled={false}
                            >
                                {(progress) => this.displayMobileHeading(progress)}
                            </Scene>
                        </Controller>
                    </Wrapper>
                    <Wrapper active={this.props.active} id="set4Wrapper2">
                        <div id="set4Scene2" />
                        <Controller>
                            <Scene
                                triggerElement="#set4Scene2"
                                triggerHook="onLeave"
                                duration={2000}
                                pin
                                enabled={false}
                            >
                                {(progress) => this.displayMobileScene(progress)}
                            </Scene>
                        </Controller>
                    </Wrapper>
                    <Wrapper active={this.props.active} id="set4Wrapper3">
                        <div id="set4Scene3" />
                        <Controller>
                            <Scene
                                triggerElement="#set4Scene3"
                                triggerHook="onLeave"
                                duration={2000}
                                pin
                                enabled={false}
                            >
                                {(progress) => this.displayMobileScene2(progress)}
                            </Scene>
                        </Controller>
                    </Wrapper>
                </>
            )
        }
    }
}


export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                 bgStep1: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt15-1-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep1Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt15-1-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep1Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt15-1-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep1Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt15-1-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                bgStep2: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt15-2-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep2Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt15-2-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep2Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt15-2-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep2Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt15-2-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Set4 data={data} active={props.active}
                  title={props.title}/>
        )}
    />
)
