// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {media} from "utils/Media"
import {Controller, Scene} from 'react-scrollmagic';
import {graphql, StaticQuery} from "gatsby";
import CopyBox from "components/shared/CopyBox"
import BgImage from 'components/shared/BgImage'
import TitleBar from 'components/shared/TitleBar'
import Absolute from "components/shared/Absolute"

const Wrapper = styled.div`
  overflow: hidden;
  
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

     @media screen and (max-width: ${props => props.theme.sizes.sm}) {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    @media ${media.sm} {
        display: block !important;
    }
  }

    h2 {
    font-size: 1.75rem;
    line-height: 120%;

    @media ${media.lg} {
      font-size: initial;
      line-height: initial;
    }

  }

  .black-bg {
      background: #000;
  }

  .no-padding-top {
      top: 0;
  }
  
  .sticky, .sticky2 {
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
     .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
`;

class Set1 extends Component {

    displayMobileHeading = (progress) => {
        return (
            <div className={`sticky black-bg`}>
                <Absolute>

                    <TitleBar
                        onClick={() => this.props.callback()}
                        absolute
                        color="green"
                    >
                        <h2>Set</h2>
                    </TitleBar>

                    <CopyBox
                        copyVerticalPosition="toTop"
                        copyHorizontalPosition="toLeft"
                        copyVerticalPositionMd="toTop"
                        copyMdWidth="fullWidth"
                        step1
                    >
                        <p>
                            The set of The Lion King needs to portray many things – the African Savannah, a waterhole, the
                            jungle, a gorge, Pride Rock, an Elephant Graveyard and more. Technical elements play an
                            important role in making this possible, from lighting to automation. However the cast, costumes
                            and choreography also help to bring these environments to life.
                    </p>
                    </CopyBox>

                </Absolute>
            </div>

        )
    }

    displayMobileScene = (progress) => {
        return (
            <div className={`sticky`}>
                <Absolute className="no-padding-top">
                    <BgImage
                        data={this.props.data}
                        play={progress > 0}
                        progress={progress}
                        noTitleBar
                    />
                </Absolute>
            </div>
        )
    }

    displayScene = (progress) => {
        return (
            <div className={`sticky`}>
                <Absolute>

                <TitleBar 
                    onClick={() => this.props.callback()}
                    absolute 
                    color="green"
                >
                    <h2>Set</h2>
                </TitleBar>

                <BgImage
                    data={this.props.data}
                    play={progress > 0}
                    progress={progress}
                    noTitleBar
                />

                <CopyBox
                    copyVerticalPosition="toTop"
                    copyHorizontalPosition="toLeft"
                    copyVerticalPositionMd="toTop"
                    copyMdWidth="fullWidth"
                    step1
                >
                    <p>
                        The set of The Lion King needs to portray many things – the African Savannah, a waterhole, the
                        jungle, a gorge, Pride Rock, an Elephant Graveyard and more. Technical elements play an
                        important role in making this possible, from lighting to automation. However the cast, costumes
                        and choreography also help to bring these environments to life.
                    </p>
                </CopyBox>
                </Absolute>
            </div>
        )
    }

    render() {
        let mql = window.matchMedia(media.sm).matches;
        if (mql) {

        return (
            <Wrapper active={this.props.active} id="set1Wrapper">
                <div id="set1Scene" ref={set1Scene => this.set1Scene = set1Scene}/>
                <Controller>
                    <Scene
                        triggerElement="#set1Scene"
                        triggerHook="onLeave"
                        duration={500}
                        pin
                    >
                        {(progress) => this.displayScene(progress)}
                    </Scene>
                </Controller>
            </Wrapper>
        )

        } else {
            return (
                <>
                    <Wrapper active={this.props.active} id="set1Wrapper">
                        <div id="set1Scene"/>
                        <Controller>
                            <Scene
                                triggerElement="#set1Scene"
                                triggerHook="onLeave"
                                duration={500}
                                pin
                                enabled={false}
                            >
                                {(progress) => this.displayMobileHeading(progress)}
                            </Scene>
                        </Controller>
                    </Wrapper>
                    <Wrapper active={this.props.active} id="set1Wrapper1">
                        <div id="set1Scene1" />
                        <Controller>
                            <Scene
                                triggerElement="#set1Scene1"
                                triggerHook="onLeave"
                                duration={500}
                                pin
                                enabled={false}
                            >
                                {(progress) => this.displayMobileScene(progress)}
                            </Scene>
                        </Controller>
                    </Wrapper>
                </>
            )
        }
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                bgStep1: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt12-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep1Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt12-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep1Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt12-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep1Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt12-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Set1 data={data} active={props.active} title={props.title} callback={(e) => props.callback(e)} />
        )}
    />
)
