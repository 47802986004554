// @flow
import React, {Component} from 'react';
import styled from 'styled-components';
import {media} from "utils/Media"
import TitleBar from 'components/shared/TitleBar'

const Wrapper = styled.div`
    overflow: hidden;
    position: relative;
    display: none;
    
    @media ${media.sm} {
      display: block;
    }
  
  .sticky { 
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    bottom: auto;
    right: auto;
    box-sizing: border-box;
    width: 100%;
    z-index: 20;
  }
`;

class ActTitle extends Component {
    render() {
        return (
            <Wrapper active={this.props.active}>
                <div className={this.props.sticky ? 'sticky' : ''}>
                    <TitleBar color={this.props.color}>
                        <h2>{this.props.title}</h2>
                    </TitleBar>
                </div>
            </Wrapper>
        )
    }
}


export default ActTitle
