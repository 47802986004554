// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';

import {Controller, Scene} from 'react-scrollmagic';
import {graphql, StaticQuery} from "gatsby";
import {media} from "utils/Media"
import SwipeScene from "components/shared/SwipeScene"
import ButtonSwipe from "components/shared/ButtonSwipe"
import CopyBox from "components/shared/CopyBox"
import BgImage from 'components/shared/BgImage'

const Wrapper = styled.div`
    overflow: hidden;
    position: relative;

 .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media screen and (max-width: ${props => props.theme.sizes.sm}) {
        padding-bottom: 0 !important;
    }

    @media ${media.sm} {
        display: block !important;
    }
  }

  .black-bg {
      background: #000;
  }
  
  .sticky, .sticky2 { 
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
  .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
`;

class Puppetry2 extends Component {

    displayMobileHeading = (progress) => {

        return (
            <div className="sticky2 black-bg">
            <CopyBox
                copyHorizontalPosition="toRight"
                copyHorizontalPositionMd="toRight"
                copyVerticalPosition="toMiddle"
                copyMdWidth="narrow"
                step1
            >
                <h3>Bunraku-INSPIRED puppets</h3>
                <p>The puppets of Zazu and Timon are inspired by Japanese Bunraku puppetry, where the puppet is held as a separate entity from the performer.  For Timon and Zazu, the costumes also depict the natural environment for each character – the green jungle and the blue sky.</p>
            </CopyBox>
            </div>
        )

    }

    displayMobileScene =  (progress) => {

        return (
            <div className="sticky2">

                <BgImage
                    data={this.props.data}
                    play={progress > 0}
                    progress={progress}
                    noTitleBar
                />

                <ButtonSwipe
                    target={this.puppetry2Swipe}
                    play={true}
                />

                <SwipeScene
                    ref={puppetry2Swipe => this.puppetry2Swipe = puppetry2Swipe}
                    bgImageSm={this.props.data.bgStep2Sm.childImageSharp.gatsbyImageData}
                    bgImageMd={this.props.data.bgStep2Md.childImageSharp.gatsbyImageData}
                    bgImageLg={this.props.data.bgStep2Lg.childImageSharp.gatsbyImageData}
                    bgImageXl={this.props.data.bgStep2.childImageSharp.gatsbyImageData}
                    copyHorizontalPosition="toRight"
                    copyVerticalPosition="toMiddle"
                />

            </div>
        )

    }

    displayScene = (progress) => {
        return (
            <div className="sticky2">
                <BgImage
                    data={this.props.data}
                    play={progress > 0}
                    progress={progress}
                    noTitleBar
                />

                <ButtonSwipe
                    target={this.puppetry2Swipe}
                    play={progress > 0}
                />

                <CopyBox
                    copyHorizontalPosition="toRight"
                    copyHorizontalPositionMd="toRight"
                    copyVerticalPosition="toMiddle"
                    copyMdWidth="narrow"
                    step1
                >
                    <h3>Bunraku-INSPIRED puppets</h3>
                    <p>The puppets of Zazu and Timon are inspired by Japanese Bunraku puppetry, where the puppet is held as a separate entity from the performer.  For Timon and Zazu, the costumes also depict the natural environment for each character – the green jungle and the blue sky.</p>
                </CopyBox>

                <SwipeScene
                    ref={puppetry2Swipe => this.puppetry2Swipe = puppetry2Swipe}
                    bgImageSm={this.props.data.bgStep2Sm.childImageSharp.gatsbyImageData}
                    bgImageMd={this.props.data.bgStep2Md.childImageSharp.gatsbyImageData}
                    bgImageLg={this.props.data.bgStep2Lg.childImageSharp.gatsbyImageData}
                    bgImageXl={this.props.data.bgStep2.childImageSharp.gatsbyImageData}
                    copyHorizontalPosition="toRight"
                    copyVerticalPosition="toMiddle"
                />
            </div>
        )
    }

    render() {

        let mql = window.matchMedia(media.sm).matches;
        if (mql) {
        return (
            <Wrapper active={this.props.active} id="puppetry2Wrapper">
                <div id="puppetry2Scene" ref={puppetry2Scene => this.puppetry2Scene = puppetry2Scene}/>
                <Controller>
                    <Scene
                        triggerElement="#puppetry2Scene"
                        triggerHook="onLeave"
                        duration={500}
                        pin
                    >
                        {(progress) => this.displayScene(progress)}
                    </Scene>
                </Controller>
            </Wrapper>
        )
        } else {
            return (
                <>
                <Wrapper active={this.props.active} id="puppetry2Wrapper" className="no-padding">
                    <div id="puppetry2Scene" ref={puppetry2Scene => this.puppetry2Scene = puppetry2Scene} />
                    <Controller>
                        <Scene
                            triggerElement="#puppetry2Scene"
                            triggerHook="onLeave"
                            duration={500}
                            pin
                            enabled={false}
                        >
                            {(progress) => this.displayMobileHeading(progress)}
                        </Scene>
                    </Controller>
                </Wrapper>
                <Wrapper active={this.props.active} id="puppetry2Wrapper1">
                    <div id="puppetry2Scene1" ref={puppetry2Scene1 => this.puppetry2Scene1 = puppetry2Scene1} />
                    <Controller>
                        <Scene
                            triggerElement="#puppetry2Scene1"
                            triggerHook="onLeave"
                            duration={500}
                            pin
                            enabled={false}
                        >
                            {(progress) => this.displayMobileScene(progress)}
                        </Scene>
                    </Controller>
                </Wrapper>
                </>

            )
        }
    }
}


export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                 bgStep1: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt10-1-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep1Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt10-1-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep1Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt10-1-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep1Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt10-1-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
               bgStep2: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt10-2-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep2Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt10-2-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep2Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt10-2-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep2Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt10-2-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Puppetry2 data={data} active={props.active} title={props.title}/>
        )}
    />
)
