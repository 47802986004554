// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {media} from "utils/Media"
import {Controller, Scene} from 'react-scrollmagic';
import {Tween, Timeline} from 'react-gsap';
import {graphql, StaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import CopyBox from "components/shared/CopyBox"
import InfoBox from "components/shared/InfoBox"
import ButtonScroll from "components/shared/ScrollButton"
import LazyLoad from "react-lazyload"

const Wrapper = styled.div`
  overflow: hidden;
  
  &.black-bg {
      background-color: ${props => props.theme.colors.black};
  }
  
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 {
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
     .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
    
    .animation__background {
        width: 100vw;
        height: 100vh;
        position: absolute;
        left: 0;
        z-index: 2;
        top: 0;
        
        &--step2, &--step3, &--step4 {
            opacity: 0;
        }
        
        &--overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            background-image: linear-gradient(#000, transparent);
        }
        
        .image {
            width: 100vw;
            height: 100vh;
        }
    }
`;

class Director2 extends Component {
    render() {
        return (
            <Wrapper active={this.props.active} id="director2Wrapper">
                <div id="director2Scene"/>
                <Controller>
                    <Scene
                        triggerElement="#director2Scene"
                        triggerHook="onLeave"
                        duration={3000}
                        pin
                    >
                        {(progress) => (
                            <div className={`sticky2`}>
                                    <Timeline totalProgress={progress} paused>
                                        <Tween
                                            ease="Strong.easeOut"
                                            totalProgress={progress}
                                            paused
                                        >
                                            <div className="animation__background">
                                                <LazyLoad height={"100%"} once>
                                                <GatsbyImage image={this.props.data.bgStep1Sm.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-block d-sm-none"
                                                />
                                                </LazyLoad>
                                                <LazyLoad height={"100%"} once>
                                                <GatsbyImage image={this.props.data.bgStep1Md.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-none d-sm-block d-lg-none"
                                                />
                                                </LazyLoad>
                                                <LazyLoad height={"100%"} once>
                                                <GatsbyImage image={this.props.data.bgStep1Lg.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-none d-lg-block d-xl-none"
                                                />
                                                </LazyLoad>
                                                <LazyLoad height={"100%"} once>
                                                <GatsbyImage image={this.props.data.bgStep1.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-none d-xl-block"
                                                />
                                                </LazyLoad>
                                                <InfoBox>
                                                <p>The fabric on her arms is inspired by African Kuba cloth</p>
                                                </InfoBox>
                                            </div>
                                        </Tween>

                                        <Tween
                                            duration={1}
                                        >
                                            <CopyBox
                                                copyVerticalPosition="toTop"
                                                copyVerticalPositionMd="toTop"
                                                copyHorizontalPosition="toLeft"
                                                step1
                                                onTop
                                            >
                                            <p className="d-none d-sm-block">
                                                    Julie was inspired by a wide range of African and Asian art and culture. This is visible in the masks and costumes, take Nala as an example.
                                                </p>
                                            </CopyBox>
                                        </Tween>

                                        <Tween
                                            to={{opacity: 1}}
                                            duration={3}
                                        >
                                            <div className="animation__background animation__background--step2">
                                                <LazyLoad height={"100%"} once>
                                                <GatsbyImage image={this.props.data.bgStep2Sm.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-block d-sm-none"
                                                />
                                                </LazyLoad>
                                                <LazyLoad height={"100%"} once>
                                                <GatsbyImage image={this.props.data.bgStep2Md.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-none d-sm-block d-lg-none"
                                                />
                                                </LazyLoad>
                                                <LazyLoad height={"100%"} once>
                                                <GatsbyImage image={this.props.data.bgStep2Lg.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-none d-lg-block d-xl-none"
                                                />
                                                </LazyLoad>
                                                <LazyLoad height={"100%"} once>
                                                <GatsbyImage image={this.props.data.bgStep2.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-none d-xl-block"
                                                />
                                                </LazyLoad>
                                                <InfoBox>
                                                    <p>Her corset inspired by those of the Dinka people in Sudan </p>
                                                </InfoBox>
                                            </div>
                                        </Tween>

                                        <Tween
                                            to={{opacity: 1}}
                                            duration={3}
                                        >
                                            <div className="animation__background animation__background--step3">
                                                <LazyLoad height={"100%"} once>
                                                <GatsbyImage image={this.props.data.bgStep3Sm.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-block d-sm-none"
                                                />
                                                </LazyLoad>
                                                <LazyLoad height={"100%"} once>
                                                <GatsbyImage image={this.props.data.bgStep3Md.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-none d-sm-block d-lg-none"
                                                />
                                                </LazyLoad>
                                                <LazyLoad height={"100%"} once>
                                                <GatsbyImage image={this.props.data.bgStep3Lg.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-none d-lg-block d-xl-none"
                                                />
                                                </LazyLoad>
                                                <LazyLoad height={"100%"} once>
                                                <GatsbyImage image={this.props.data.bgStep3.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-none d-xl-block"
                                                />
                                                </LazyLoad>
                                                <InfoBox>
                                                    <p>Her collar is inspired by Balinese culture</p>
                                                </InfoBox>
                                            </div>
                                        </Tween>

                                        <Tween
                                            to={{opacity: 1}}
                                            duration={3}
                                        >
                                            <div className="animation__background animation__background--step4">
                                                <LazyLoad height={"100%"} once>
                                                <GatsbyImage image={this.props.data.bgStep4Sm.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-block d-sm-none"
                                                />
                                                </LazyLoad>
                                                <LazyLoad height={"100%"} once>
                                                <GatsbyImage image={this.props.data.bgStep4Md.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-none d-sm-block d-lg-none"
                                                />
                                                </LazyLoad>
                                                <LazyLoad height={"100%"} once>
                                                <GatsbyImage image={this.props.data.bgStep4Lg.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-none d-lg-block d-xl-none"
                                                />
                                                </LazyLoad>
                                                <LazyLoad height={"100%"} once>
                                                <GatsbyImage image={this.props.data.bgStep4.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    title=""
                                                    className="image d-none d-xl-block"
                                                />
                                                </LazyLoad>
                                                <InfoBox>
                                                    <p>Nala’s makeup is inspired by the African Wodaabe tribe.</p>
                                                </InfoBox>
                                            </div>
                                        </Tween>

                                    </Timeline>
                                    <ButtonScroll
                                        progress={progress}
                                        story
                                    />
                            </div>
                        )}
                    </Scene>
                </Controller>
            </Wrapper>
        )
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                bgStep1: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt2-1-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep1Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt2-1-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep1Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt2-1-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep1Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt2-1-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                bgStep2: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt2-2-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep2Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt2-2-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep2Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt2-2-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep2Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt2-2-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                bgStep3: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt2-3-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep3Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt2-3-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep3Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt2-3-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep3Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt2-3-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                bgStep4: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt2-4-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep4Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt2-4-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep4Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt2-4-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep4Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt2-4-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Director2 data={data} active={props.active} title={props.title}/>
        )}
    />
)
