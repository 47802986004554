// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {media} from "utils/Media"
import {Controller, Scene} from 'react-scrollmagic';
import { Tween, Timeline } from "react-gsap"
import {graphql, StaticQuery} from "gatsby";
import CopyBox from "components/shared/CopyBox"
import CopyBoxWrapper from "components/shared/CopyBoxWrapper"
import BgImage from 'components/shared/BgImage'

import TitleBar from 'components/shared/TitleBar'
import Absolute from "components/shared/Absolute"


const Relative = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Wrapper = styled.div`
  overflow: hidden;
  
  
  &.black-bg {
      background-color: ${props => props.theme.colors.black};
  }
  
  .scrollmagic-pin-spacer {
    display: none !important;
    

    ${props => props.active && css`
        display: block !important;
    `}

    @media screen and (max-width: ${props => props.theme.sizes.sm}) {
      padding-bottom: 0 !important;
    }

    @media ${media.sm} {
        display: block !important;
    }
  }

  h2 {
    font-size: 1.75rem;
    line-height: 120%;

    @media ${media.lg} {
      font-size: initial;
      line-height: initial;
    }

  }
  
  .sticky, .sticky2 {
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 200vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
     .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }

    .margin-bottom-80 {
        margin-bottom: 80px;
    }
`;

const StyledBgImage = styled(BgImage)`
`;

const CopyBoxWrapperStyled = styled(CopyBoxWrapper)`
  @media ${media.sm} {
    height: 92%;
  }
`;

const CopyBoxStyled = styled(CopyBox)`
  @media ${media.sm} {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    bottom: 0;
    padding: 28px;
    left: 0;
  }

  @media ${media.lg} {
    background: initial;
    width: initial;
    bottom: initial;
    padding: initial;
    left: initial;
  }
`

class Director1 extends Component {

    displayScene = (progress) => {

        return (
          <div className={`sticky`}>
            <Absolute>
              <Timeline totalProgress={progress} paused>
                <Tween
                  ease="Strong.easeOut"
                  totalProgress={progress * 2}
                  paused
                >
                  <TitleBar 
                    onClick={() => this.props.callback()}
                    absolute 
                    color="yellow">
                    <h2>Julie Taymor – Director</h2>
                  </TitleBar>
                </Tween>

                <StyledBgImage
                  data={this.props.data}
                  play={progress > 0}
                  progress={progress}
                  
                  noTitleBar
                />

                <CopyBoxWrapperStyled>
                  <Relative>
                    <CopyBoxStyled
                      copyHorizontalPosition="toRight"
                      copyHorizontalPositionMd="toLeft"
                      copyVerticalPositionMd="toBottom"
                      copyVerticalPositionXs="toBottom"
                      copyMdWidth="fullWidth"
                      noScroller
                      step1
                    >
                      <h3>INSPIRATION</h3>
                      <p>
                        Julie Taymor is the visionary director who transformed
                        The Lion King from an animated film into a theatrical
                        production. Julie directed the show, designed all of the
                        costumes, masks and puppets and contributed some
                        additional lyrics. Julie used a wide variety of
                        theatrical styles to create the stage production.
                      </p>

                      <p className="margin-bottom-80 d-block d-sm-none">
                        Julie was inspired by a wide range of African and Asian
                        art and culture. This is visible in the masks and
                        costumes, take Nala as an example.
                      </p>
                    </CopyBoxStyled>
                  </Relative>
                </CopyBoxWrapperStyled>
              </Timeline>
            </Absolute>
          </div>
        )
    }

    render() {

        let mql = window.matchMedia(media.sm).matches;

        return (
            <>
                <Wrapper active={this.props.active} id="director1Wrapper" className="black-bg">
                    <div id="director1Scene" ref={director1Scene => this.director1Scene = director1Scene}/>
                    <Controller>
                        <Scene
                            triggerElement="#director1Scene"
                            triggerHook="onLeave"
                            duration={500}
                            pin
                            enabled={mql}
                        >
                            {(progress) => this.displayScene(progress)}
                        </Scene>
                    </Controller>
                </Wrapper>
                
            </>
        )
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                bgStep1: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt1-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep1Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt1-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep1Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt1-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep1Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt1-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Director1 data={data} active={props.active} title={props.title} callback={(e) => props.callback(e)}/>
        )}
    />
)
