// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {media} from "utils/Media"
import {Controller, Scene} from 'react-scrollmagic';
import {graphql, StaticQuery} from "gatsby";
import CopyBox from "components/shared/CopyBox"
import BgImage from 'components/shared/BgImage'

const Wrapper = styled.div`
  overflow: hidden;
  
  &.black-bg {
      background-color: ${props => props.theme.colors.black};
  }
  
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media screen and (max-width: ${props => props.theme.sizes.sm}) {
      padding-bottom: 0 !important;
    }
    
    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 {
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }

    &.auto-height {
        height: auto;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
     .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
`;

class Costumes2 extends Component {
    displayScene = (progress) => {
        return (
            <div className={`sticky`}>
                <BgImage
                    data={this.props.data}
                    play={progress > 0}
                    progress={progress}
                    noTitleBar
                />

                <CopyBox
                    copyHorizontalPosition="toRight"
                    copyHorizontalPositionMd="toLeft"
                    copyVerticalPosition="toTop"
                    copyVerticalPositionMd="toTop"
                    copyMdWidth="fullWidth"
                    step1
                >
                    <h3>Mufasa and Scar</h3>
                    <p>
                        The characters of Mufasa and Scar are brothers. They are both adult lions, but their appearance
                        is vital in communicating their very different characters. The make-up, mask and costume,
                        combined with the actors’ body language and stylised movement convey the differing
                        personalities.
                    </p>
                </CopyBox>
            </div>
        )
    }

    displayMobileCopyScene = (progress) => {
        return (
            <div className={`sticky`}>
                <CopyBox
                    copyHorizontalPosition="toRight"
                    copyHorizontalPositionMd="toLeft"
                    copyVerticalPosition="toTop"
                    copyVerticalPositionMd="toTop"
                    copyMdWidth="fullWidth"
                    step1
                >
                    <h3>Mufasa and Scar</h3>
                    <p>
                        The characters of Mufasa and Scar are brothers. They are both adult lions, but their appearance
                        is vital in communicating their very different characters. The make-up, mask and costume,
                        combined with the actors’ body language and stylised movement convey the differing
                        personalities.
                    </p>
                </CopyBox>
            </div>
        )
    }

    displayMobileBgScene = (progress) => {
        return (
            <div className={`sticky`}>
                <BgImage
                    data={this.props.data}
                    play={progress > 0}
                    progress={progress}
                    noTitleBar
                />
            </div>
        )
    }

    render() {

        let mql = window.matchMedia(media.sm);

        if (mql.matches) {

            return (
            <>
            <Wrapper active={this.props.active} id="costumes2Wrapper" className="">
                <div id="costumes2Scene" ref={costumes2Scene => this.costumes2Scene = costumes2Scene} />
                <Controller>
                    <Scene
                        triggerElement="#costumes2Scene"
                        triggerHook="onLeave"
                        duration={500}
                        pin
                    >
                        {(progress) => this.displayScene(progress)}
                    </Scene>
                </Controller>
            </Wrapper>
            </>
            )

        } else {

            return (
                <>
                    <Wrapper active={this.props.active} id="costumes2Wrapper2" className="black-bg">
                        <div id="costumes2Scene2" ref={costumes2Scene2 => this.costumes2Scene2 = costumes2Scene2} />
                        <Controller>
                            <Scene
                                triggerElement="#costumes2Scene2"
                                triggerHook="onLeave"
                                duration={500}
                                pin
                                enabled={false}
                            >
                                {(progress) => this.displayMobileCopyScene(progress)}
                            </Scene>
                        </Controller>
                    </Wrapper>

                    <Wrapper active={this.props.active} id="costumes2Wrapper3" className="">
                        <div id="costumes2Scene3" ref={costumes2Scene3 => this.costumes2Scene3 = costumes2Scene3} />
                        <Controller>
                            <Scene
                                triggerElement="#costumes2Scene3"
                                triggerHook="onLeave"
                                duration={500}
                                pin
                                enabled={false}
                            >
                                {(progress) => this.displayMobileBgScene(progress)}
                            </Scene>
                        </Controller>
                    </Wrapper>
                </>
            )

        }
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                bgStep1: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt6-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep1Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt6-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep1Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt6-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep1Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt6-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Costumes2 data={data} active={props.active} title={props.title}/>
        )}
    />
)
