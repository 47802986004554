// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {media} from "utils/Media"
import {Controller, Scene} from 'react-scrollmagic';
import {Tween, Timeline} from 'react-gsap';
import {graphql, StaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import CopyBox from "components/shared/CopyBox"
import InfoBox from "components/shared/InfoBox"
import ButtonScroll from "components/shared/ScrollButton"
import LazyLoad from "react-lazyload"
import TitleBar from 'components/shared/TitleBar'
import Absolute from "components/shared/Absolute"



const Wrapper = styled.div`
  overflow: hidden;
  
  &.black-bg {
      background-color: ${props => props.theme.colors.black};
  }

  &#costumes1Wrapper1 {
    .scrollmagic-pin-spacer {
        @media screen and (max-width: ${props => props.theme.sizes.sm}) {
            padding-bottom: 0 !important;
        }
    }
  }
  
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media ${media.sm} {
        display: block !important;
    }
  }

    h2 {
    font-size: 1.75rem;
    line-height: 120%;

    @media ${media.lg} {
      font-size: initial;
      line-height: initial;
    }

  }
  
  .sticky, .sticky2 {
    height: 0;
    overflow: hidden;
    width: 100% !important;

    background-color: ${props => props.theme.colors.darkBlue};
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
     .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
    
    .animation__background {
        width: 100vw;
        height: 100vh;
        position: absolute;
        left: 0;
        z-index: 2;
        top: 0;
        
        &--step2, &--step3, &--step4 {
            opacity: 0;
        }
        
        &--overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            background-image: linear-gradient(#000, transparent);
        }
        
        .image {
            width: 100vw;
            height: 100vh;
        }
    }
`;

class Costumes1 extends Component {

    displayMobileHeading = (progress) => {

        return (
            <div className={`sticky black-bg`}>
                <Absolute>

                    <TitleBar
                        onClick={() => this.props.callback()}
                        absolute
                        color="orange"
                    >
                        <h2>Costumes and Masks</h2>
                    </TitleBar>

                    <Tween
                        duration={1}
                    >
                        <CopyBox
                            copyVerticalPosition="toTop"
                            copyVerticalPositionMd="toTop"
                            copyHorizontalPosition="toLeft"
                            step1
                            onTop
                        >
                            <p>
                                The costumes and masks in The Lion King tell a story.  The lions’ masks sit on top of their heads like crowns because they are royal.
                                </p>
                        </CopyBox>
                    </Tween>


                </Absolute>

            </div>
        )

    }

    displayMobileScene = (progress) => {

        return (

            <div className={`sticky`}>
                    <Timeline totalProgress={progress} paused>

                        <Tween
                            ease="Strong.easeOut"
                            totalProgress={progress}
                            paused
                        >
                            <div className="animation__background">
                                <LazyLoad height={"100%"} once>
                                    <GatsbyImage image={this.props.data.bgStep1Sm.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="image d-block d-sm-none"
                                    />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                    <GatsbyImage image={this.props.data.bgStep1Md.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="image d-none d-sm-block d-lg-none"
                                    />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                    <GatsbyImage image={this.props.data.bgStep1Lg.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="image d-none d-lg-block d-xl-none"
                                    />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                    <GatsbyImage image={this.props.data.bgStep1.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="image d-none d-xl-block"
                                    />
                                </LazyLoad>
                                <InfoBox big>
                                    <p>As an adult, Simba’s mask resembles a Roman warrior soldier with the plume down the centre, indicating that he is strong and brave.</p>

                                </InfoBox>
                            </div>
                        </Tween>

                        <Tween
                            to={{ opacity: 1 }}
                            duration={3}
                        >
                            <div className="animation__background animation__background--step2">
                                <LazyLoad height={"100%"} once>
                                    <GatsbyImage image={this.props.data.bgStep2Sm.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="image d-block d-sm-none"
                                    />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                    <GatsbyImage image={this.props.data.bgStep2Md.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="image d-none d-sm-block d-lg-none"
                                    />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                    <GatsbyImage image={this.props.data.bgStep2Lg.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="image d-none d-lg-block d-xl-none"
                                    />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                    <GatsbyImage image={this.props.data.bgStep2.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="image d-none d-xl-block"
                                    />
                                </LazyLoad>
                                <InfoBox big>
                                    <p>However his mask is jawless implying that he is still a young adult.</p>

                                </InfoBox>
                            </div>
                        </Tween>

                        <Tween
                            to={{ opacity: 1 }}
                            duration={3}
                        >
                            <div className="animation__background animation__background--step3">
                                <LazyLoad height={"100%"} once>
                                    <GatsbyImage image={this.props.data.bgStep3Sm.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="image d-block d-sm-none"
                                    />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                    <GatsbyImage image={this.props.data.bgStep3Md.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="image d-none d-sm-block d-lg-none"
                                    />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                    <GatsbyImage image={this.props.data.bgStep3Lg.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="image d-none d-lg-block d-xl-none"
                                    />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                    <GatsbyImage image={this.props.data.bgStep3.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="image d-none d-xl-block"
                                    />
                                </LazyLoad>
                                <InfoBox big>
                                    <p>The beading on his corset and his red paint are inspired by the Maasai tribe and connect him visually to Mufasa, his father.</p>

                                </InfoBox>
                            </div>
                        </Tween>

                        <Tween
                            to={{ opacity: 1 }}
                            duration={3}
                        >
                            <div className="animation__background animation__background--step4">
                                <LazyLoad height={"100%"} once>
                                    <GatsbyImage image={this.props.data.bgStep4Sm.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="image d-block d-sm-none"
                                    />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                    <GatsbyImage image={this.props.data.bgStep4Md.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="image d-none d-sm-block d-lg-none"
                                    />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                    <GatsbyImage image={this.props.data.bgStep4Lg.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="image d-none d-lg-block d-xl-none"
                                    />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                    <GatsbyImage image={this.props.data.bgStep4.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="image d-none d-xl-block"
                                    />
                                </LazyLoad>
                                <InfoBox big>
                                    <p>His corset emphasises the width and strength of his shoulders.</p>

                                </InfoBox>
                            </div>
                        </Tween>

                    </Timeline>
                    <ButtonScroll
                        progress={progress}
                        story
                    />
            </div>    
        )

    }

    displayScene = (progress) => {

        return (
            <div className={`sticky`}>
                <Absolute>

                    <TitleBar 
                        onClick={() => this.props.callback()}
                        absolute 
                        color="orange"
                    >
                        <h2>Costumes and Masks</h2>
                    </TitleBar>

                    <Timeline totalProgress={progress} paused>

                        <Tween
                            duration={1}
                        >
                            <CopyBox
                                copyVerticalPosition="toTop"
                                copyVerticalPositionMd="toTop"
                                copyHorizontalPosition="toLeft"
                                step1
                                onTop
                            >
                                <p>
                                    The costumes and masks in The Lion King tell a story.  The lions’ masks sit on top of their heads like crowns because they are royal.
                                </p>
                            </CopyBox>
                        </Tween>

                        <Tween
                            ease="Strong.easeOut"
                            totalProgress={progress}
                            paused
                        >
                            <div className="animation__background">
                                <LazyLoad height={"100%"} once>
                                <GatsbyImage image={this.props.data.bgStep1Sm.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="image d-block d-sm-none"
                                />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                <GatsbyImage image={this.props.data.bgStep1Md.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="image d-none d-sm-block d-lg-none"
                                />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                <GatsbyImage image={this.props.data.bgStep1Lg.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="image d-none d-lg-block d-xl-none"
                                />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                <GatsbyImage image={this.props.data.bgStep1.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="image d-none d-xl-block"
                                />
                                </LazyLoad>
                                <InfoBox big>
                                    <p>As an adult, Simba’s mask resembles a Roman warrior soldier with the plume down the centre, indicating that he is strong and brave.</p>

                                </InfoBox>
                            </div>
                        </Tween>

                        <Tween
                            to={{ opacity: 1 }}
                            duration={3}
                        >
                            <div className="animation__background animation__background--step2">
                                <LazyLoad height={"100%"} once>
                                <GatsbyImage image={this.props.data.bgStep2Sm.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="image d-block d-sm-none"
                                />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                <GatsbyImage image={this.props.data.bgStep2Md.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="image d-none d-sm-block d-lg-none"
                                />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                <GatsbyImage image={this.props.data.bgStep2Lg.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="image d-none d-lg-block d-xl-none"
                                />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                <GatsbyImage image={this.props.data.bgStep2.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="image d-none d-xl-block"
                                />
                                </LazyLoad>
                                <InfoBox big>
                                    <p>However his mask is jawless implying that he is still a young adult.</p>

                                </InfoBox>
                            </div>
                        </Tween>

                        <Tween
                            to={{ opacity: 1 }}
                            duration={3}
                        >
                            <div className="animation__background animation__background--step3">
                                <LazyLoad height={"100%"} once>
                                <GatsbyImage image={this.props.data.bgStep3Sm.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="image d-block d-sm-none"
                                />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                <GatsbyImage image={this.props.data.bgStep3Md.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="image d-none d-sm-block d-lg-none"
                                />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                <GatsbyImage image={this.props.data.bgStep3Lg.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="image d-none d-lg-block d-xl-none"
                                />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                <GatsbyImage image={this.props.data.bgStep3.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="image d-none d-xl-block"
                                />
                                </LazyLoad>
                                <InfoBox big>
                                    <p>The beading on his corset and his red paint are inspired by the Maasai tribe and connect him visually to Mufasa, his father.</p>

                                </InfoBox>
                            </div>
                        </Tween>

                        <Tween
                            to={{ opacity: 1 }}
                            duration={3}
                        >
                            <div className="animation__background animation__background--step4">
                                <LazyLoad height={"100%"} once>
                                <GatsbyImage image={this.props.data.bgStep4Sm.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="image d-block d-sm-none"
                                />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                <GatsbyImage image={this.props.data.bgStep4Md.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="image d-none d-sm-block d-lg-none"
                                />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                <GatsbyImage image={this.props.data.bgStep4Lg.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="image d-none d-lg-block d-xl-none"
                                />
                                </LazyLoad>
                                <LazyLoad height={"100%"} once>
                                <GatsbyImage image={this.props.data.bgStep4.childImageSharp.gatsbyImageData}
                                    alt=""
                                    title=""
                                    className="image d-none d-xl-block"
                                />
                                </LazyLoad>
                                <InfoBox big>
                                    <p>His corset emphasises the width and strength of his shoulders.</p>

                                </InfoBox>
                            </div>
                        </Tween>

                    </Timeline>
                    <ButtonScroll
                        progress={progress}
                        story
                    />
                </Absolute>
            </div>
        )
    }

    render() {

        let mql = window.matchMedia(media.sm).matches;

        if (mql) {

            return (
                <>
                <Wrapper active={this.props.active} id="costumes1Wrapper">
                    <div id="costumes1Scene" />
                    <Controller>
                        <Scene
                            triggerElement="#costumes1Scene"
                            triggerHook="onLeave"
                            duration={3000}
                            pin
                            
                        >
                            {(progress) => this.displayScene(progress)}
                        </Scene>
                    </Controller>
                </Wrapper>
                </>
            )

        } else {

            return (
                <>
                    <Wrapper active={this.props.active} id="costumes1Wrapper1">
                        <div id="costumes1Scene1" />
                        <Controller>
                            <Scene
                                triggerElement="#costumes1Scene1"
                                triggerHook="onLeave"
                                duration={500}
                                pin
                                enabled={false}

                            >
                                {(progress) => this.displayMobileHeading(progress)}
                            </Scene>
                        </Controller>
                    </Wrapper>
                    <Wrapper active={this.props.active} id="costumes1Wrapper2">
                        <div id="costumes1Scene2" />
                        <Controller>
                            <Scene
                                triggerElement="#costumes1Scene2"
                                triggerHook="onLeave"
                                duration={2500}
                                pin

                            >
                                {(progress) => this.displayMobileScene(progress)}
                            </Scene>
                        </Controller>
                    </Wrapper>
                </>
            )
        }
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                bgStep1: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt5-1-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep1Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt5-1-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep1Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt5-1-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep1Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt5-1-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                bgStep2: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt5-2-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep2Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt5-2-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep2Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt5-2-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep2Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt5-2-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                bgStep3: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt5-3-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep3Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt5-3-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep3Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt5-3-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep3Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt5-3-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                bgStep4: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt5-4-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep4Lg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt5-4-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep4Md: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt5-4-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep4Sm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-pt5-4-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Costumes1 data={data} active={props.active} title={props.title} callback={(e) => props.callback(e)}/>
        )}
    />
)
