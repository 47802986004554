import React, {Component} from "react"
import styled, { css } from "styled-components"
import { media } from "utils/Media"
import LayoutInteractive from "components/Layout/LayoutInteractive"
import ParallaxImage from "components/Interactive/ParallaxImage"
import Menu from "components/Interactive/Menu"
import TitleBar from 'components/shared/TitleBar'
import ActTitle from "components/Interactive/ActTitle"
import Director1 from "components/Interactive/Production/Director1"
import Director2 from "components/Interactive/Production/Director2"
import Director3 from "components/Interactive/Production/Director3"
import Director4 from "components/Interactive/Production/Director4"
import Costumes1 from "components/Interactive/Production/Costumes1"
import Costumes2 from "components/Interactive/Production/Costumes2"
import Costumes3 from "components/Interactive/Production/Costumes3"
import Costumes4 from "components/Interactive/Production/Costumes4"
import Puppetry1 from "components/Interactive/Production/Puppetry1"
import Puppetry2 from "components/Interactive/Production/Puppetry2"
import Puppetry3 from "components/Interactive/Production/Puppetry3"
import Set1 from "components/Interactive/Production/Set1"
import Set2 from "components/Interactive/Production/Set2"
import Set3 from "components/Interactive/Production/Set3"
import Set4 from "components/Interactive/Production/Set4"

import DownloadButton from "components/shared/DownloadButton"
import IconDownload from 'images/icons/icon-download-white.svg'

import { graphql, StaticQuery, Link } from "gatsby"
import {isBrowser, isChrome} from "react-device-detect";

const MobileTitleBar = styled(TitleBar)`
    display: block;
    cursor: pointer;
    
    &.hidden {
        display: none;
    }
    
    h2 {
       font-size: 1.75rem;
       line-height: 120%;
    }
    
    @media ${media.sm} {
        display: none;
        
        &.hidden {
            display: block;
        }
    }
    
    ${props => props.color === 'purple' && css`
      color: ${props => props.theme.colors.white};
    `}
`

const scrollToElement = require('scroll-to-element');

const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                image: file(relativePath: { eq: "Interactive/Production/lke-im-prod-header-1920-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                imageLg: file(relativePath: { eq: "Interactive/Production/lke-im-prod-header-1440-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
               
                imageSm: file(relativePath: { eq: "Interactive/Production/lke-im-prod-header-991-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                imageXs: file(relativePath: { eq: "Interactive/Production/lke-im-prod-header-576-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <ProductionPage data={data}/>
        )}
    />
)

class ProductionPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
          scene: "",
          displayContent: false,
          imagesAreLoaded: [],
          activeSceneName: 'Julie Taymor – Director"',
          activePageName: "3/3 About The Production",
          sceneList: [
            {
              name: "Julie Taymor – Director",
              slug: "director1",
              uri: "/interactive/about-the-production/julie-taymor",
              color: "yellow",
              active: true,
            },
            {
              name: "Costumes and Masks",
              slug: "costumes1",
              uri: "/interactive/about-the-production/costumes-and-masks",
              color: "orange",
              active: false,
            },
            {
              name: "Puppetry",
              slug: "puppetry1",
              uri: "/interactive/about-the-production/puppetry",
              color: "purple",
              active: false,
            },
            {
              name: "Set",
              slug: "set1",
              uri: "/interactive/about-the-production/set",
              color: "green",
              active: false,
            },
          ],
          pageList: [
            {
              name: "1/3 The Story",
              slug: "/interactive/the-story",
              active: false,
            },
            {
              name: "2/3 Meet the Characters",
              slug: "/interactive/meet-the-characters",
              active: false,
            },
            {
              name: "3/3 About The Production",
              slug: "/interactive/about-the-production",
              active: true,
            },
              // {
              //     name: "4/4 Fascinating Facts",
              //     slug: "/interactive/fascinating-facts",
              //     active: false,
              // }
          ],
        }
    }

    toggleScene = (scene) => {
        this.setState({scene}, () => {
            scrollToElement(`#${scene}Scene`, {
                duration: 300
            })
        })

    }

    imagesLoaded = (isLoaded) => {
        let imagesAreLoaded = this.state.imagesAreLoaded
        let displayContent = this.state.displayContent

        imagesAreLoaded.push(isLoaded)

        if (isBrowser) {
            if (imagesAreLoaded.length === 1) {
                displayContent = true
            }
        } else {
            displayContent = true
        }

        this.setState({imagesAreLoaded, displayContent})
    }

    handleHideScene() {

        this.setState({
            scene: ""
        })

    }

    render() {
        return (
            <LayoutInteractive slug="interactive/about-the-production" title="About the production">
                <Menu
                    sceneList={this.state.sceneList}
                    activeSceneName={this.state.activeSceneName}
                    pageList={this.state.pageList}
                />
                <ParallaxImage imagesAreLoaded={this.imagesLoaded} data={this.props.data} title="ABOUT THE<br/>PRODUCTION"/>

                {this.state.displayContent &&
                <React.Fragment>

                    <MobileTitleBar
                        className={this.state.scene === 'director' ? 'hidden' : ''}
                        color="yellow"
                        lessPadding
                        onClick={() => this.toggleScene('director')}
                    >
                        <h2>Julie Taymor – Director</h2>
                    </MobileTitleBar>

                    <Director1 
                        callback={(e) => this.handleHideScene()}
                        active={this.state.scene === 'director'}
                    />
                    <Director2 active={this.state.scene === 'director'}/>
                    <Director3 active={this.state.scene === 'director'}/>
                    <Director4 active={this.state.scene === 'director'}/>

                    <MobileTitleBar
                        className={this.state.scene === 'costumes' ? 'hidden' : ''}
                        color="orange"
                        lessPadding
                        onClick={() => this.toggleScene('costumes')}
                    >
                        <h2>Costumes and Masks</h2>
                    </MobileTitleBar>

                    <Costumes1 
                        callback={(e) => this.handleHideScene()}
                        active={this.state.scene === 'costumes'}
                    />
                    <Costumes2 active={this.state.scene === 'costumes'}/>
                    <Costumes3 active={this.state.scene === 'costumes'}/>
                    <Costumes4 active={this.state.scene === 'costumes'}/>

                    <MobileTitleBar
                        className={this.state.scene === 'puppetry' ? 'hidden' : ''}
                        color="purple"
                        lessPadding
                        onClick={() => this.toggleScene('puppetry')}
                    >
                        <h2>Puppetry</h2>
                    </MobileTitleBar>

                    <Puppetry1 
                        callback={(e) => this.handleHideScene()}
                        active={this.state.scene === 'puppetry'}
                    />
                    <Puppetry2 active={this.state.scene === 'puppetry'}/>
                    <Puppetry3 active={this.state.scene === 'puppetry'}/>

                    <MobileTitleBar
                        className={this.state.scene === 'set' ? 'hidden' : ''}
                        color="green"
                        lessPadding
                        onClick={() => this.toggleScene('set')}
                    >
                        <h2>Set</h2>
                    </MobileTitleBar>

                    <Set1 
                        callback={(e) => this.handleHideScene()}
                        active={this.state.scene === 'set'}
                    />
                    <Set2 active={this.state.scene === 'set'}/>
                    <Set3 active={this.state.scene === 'set'}/>
                    <Set4 active={this.state.scene === 'set'}/>

                </React.Fragment>
                }
                <DownloadButton className="mx-auto mb-4 mt-5" download target="_blank" href="/pdfs/Disney_The-Lion-King_Production_Notes.pdf">Download&nbsp;PDF <img src={IconDownload} alt="" /></DownloadButton>
            </LayoutInteractive>
        )
    }
}

export default Query